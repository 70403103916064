import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)

  },
  {
    path: 'detalle/:id',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)

  },
  {
    path: 'modalimage',
    loadChildren: () => import('./image-modal/image-modal.module').then( m => m.ImageModalPage)
  },
   {
    path: 'sipocdetalle/:id',
    loadChildren: () => import('./sipocdetalle/sipocdetalle.module').then( m => m.SipocDetallePageModule)

  },
  {
    path: 'gestionmp/:id',
    loadChildren: () => import('./gestionmp/gestionmp.module').then( m => m.GestionMPPageModule)

  },
  {
    path: 'verdatos/:id',
    loadChildren: () => import('./verdatos/verdatos-modal.module').then( m => m.VerDatosModalPage)

  },
  {
    path: 'list',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
