import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { UserService } from './services/user.service';
import { Router } from '@angular/router';

import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  StatusBarStyle,

} from '@capacitor/core';

const { PushNotifications,SplashScreen,StatusBar } = Plugins;



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],providers: [UserService
  ]
})
export class AppComponent {
  public identity;
  isStatusBarLight = true

  constructor(
    private platform: Platform,
    private _userService: UserService,
    private _router: Router,
  ) {
    this.initializeApp();
    this.identy();

  }
  logout(){
    localStorage.clear();
    this.identity=null;
    this._router.navigate(['/login']);


  }
  initializeApp() {
    this.platform.ready().then(() => {

      StatusBar.setStyle({
        style: this.isStatusBarLight ? StatusBarStyle.Dark : StatusBarStyle.Light
      });
      
      SplashScreen.show({
        autoHide: true
      });
      this.iniciarliarNotificion();

    });
  }
  identy(){
    try {
      this.identity= this._userService.getIdentity();

    } catch (error) {
      
    }
  }
  iniciarliarNotificion(){


    PushNotifications.addListener('registration',
    (token: PushNotificationToken) => {
      alert(token);
      localStorage.setItem('token', token.value);

      //this.user.token =  token.value;
    }
  );



    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );


    
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotification) => {
        //alert('Push received: ' + JSON.stringify(notification));
        this._router.navigate(['/list/tabs/tab5']);

      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        //alert('Push action performed: ' + JSON.stringify(notification));
        //console.log("Received in foreground");
        this._router.navigate(['/list/tabs/tab5']);
        
      }
    );

 
  

  }
  ngDoCheck(){
    try {
      this.identity = this._userService.getIdentity();
    if( this.identity){
      return true;
    }else{
      return false;

    }
    } catch (error) {
      return false;

    }
    

  }
}
