import {Injectable} from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GLOBAL} from './global';
import { User } from '../models/user';
import { UserService } from './user.service';

@Injectable()
export class SipocService{
    public url:string;
    public user;



    constructor( public _http: HttpClient, private _userService: UserService){
        this.url = GLOBAL.urlEcobasa;
        this.user = this._userService.getUsuario();

    }

    
    getSipocMaquina(maquina): Observable<any>{        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
    headers.append("Accept", 'application/json');
    
    return this._http.get(this.url + "getsipocmaquina/"+maquina, {headers: headers});
    }

    getsipocapagar(id,maquina,recibida,producida,ocp,materialdevolver): Observable<any>{        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
    headers.append("Accept", 'application/json');

    return this._http.post(this.url + "getsipocapagar/",{usuario :this.user.usuario,id : id,maquina : maquina,recibida : recibida,
        producida : producida, ocp : ocp,materialdevolver : materialdevolver}, {headers: headers});
    }
    getsipocprender(id,maquina): Observable<any>{        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
    headers.append("Accept", 'application/json');

    return this._http.post(this.url + "getsipocprender/" ,{usuario :this.user.usuario, maquina: maquina ,id:id}, {headers: headers});
    }

    getsipocprender2(id,maquina): Observable<any>{        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
    headers.append("Accept", 'application/json');

    return this._http.get(this.url + "getsipocprender2/"+ id + "/" + maquina, {headers: headers});
    }

    getimagenes(id,maquina): Observable<any>{        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
    headers.append("Accept", 'application/json');

    return this._http.get(this.url + "getimagenes/"+ id + "/" + maquina, {headers: headers});
    }
    getverdatos(id,maquina): Observable<any>{        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
    headers.append("Accept", 'application/json');

    return this._http.get(this.url + "getverdatos/"+ id + "/" + maquina, {headers: headers});
    }
    

    getSipoc(): Observable<any>{        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
        headers.append("Accept", 'application/json');
        
        return this._http.get(this.url + "getsipoc", {headers: headers});
    }
    detalle(id,componenete): Observable<any>{        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
    headers.append("Accept", 'application/json');
    
    return this._http.get(this.url + "getsipocdetalle/"+id+"/"+componenete ,{headers: headers});

    }
    detalleInventario(id): Observable<any>{        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
    headers.append("Accept", 'application/json');
    
    return this._http.get(this.url + "getsipocdetalle/"+id ,{headers: headers});

    }
    saveinventario(id,inventario): Observable<any>{        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
    headers.append("Accept", 'application/json');

    return this._http.post(this.url + "inventario/"+id ,inventario,{headers: headers});

    }

}

