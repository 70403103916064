import {Injectable} from '@angular/core';
import {HttpClient,HttpHeaders,JsonpClientBackend} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GLOBAL} from './global';

@Injectable()
export class UserService{
    public url:string;
    public identity:string;
    public token:string;
    public stats:string;
    public permisos:string;

    public user:string;
    public firebase:string;
    public usuario:string;

    


    constructor( public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    


    signup(user): Observable<any>{
       
        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
        headers.append("Accept", 'application/json');
        
        return this._http.post(this.url + "login_movil",user, {headers: headers});
    }

    tokenfirebase(id,user): Observable<any>{
       
        let headers= new HttpHeaders().set('Content-type','application/x-www-form-urlencoded');
        headers.append("Accept", 'application/json');
        
        return this._http.post(this.url + "tokenfirebase/"+ id ,user, {headers: headers});
    }


    getIdentity(){
        let identity =JSON.parse(localStorage.getItem('identity'));

        if(identity !== "undefined"){
            this.identity=identity;
        }else{
            this.identity=null;

        }
        return this.identity;
    }

    getUsuario(){
        let usuario =JSON.parse(localStorage.getItem('usuario'));

        if(usuario !== "undefined"){
            this.usuario=usuario;
        }else{
            this.usuario=null;

        }
        return this.usuario;
    }

    getFirebase(){
        let firebase =JSON.parse(localStorage.getItem('token'));

        if(firebase !== "undefined"){
            this.firebase=firebase;
        }else{
            this.firebase=null;

        }
        return this.firebase;
    }

    getPermisos(){
        let permisos =JSON.parse(localStorage.getItem('permisos'));

        if(permisos !== "undefined"){
            this.permisos=permisos;
        }else{
            this.permisos=null;

        }
        return this.permisos;
    }

    getUser(){
        let user =JSON.parse(localStorage.getItem('user'));

        if(user !== "undefined"){
            this.user=user;
        }else{
            this.user=null;

        }
        return this.user;
    }
  
 

    
  
}

