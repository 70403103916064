export class RQC {
  constructor(

        
    public id: string,
    public id_user: string,
    public id_workstatuparciales: string,
    public id_workstatusend: string,
    public updated_at: string,

    public CantidadEsperada: string,
    public CantidadProgramada: string,
    public CantidadRQ: string,
    public NumRQ: string,
    public ClaveMP: string,
    public Corrugado: string,
    public Cantidad: string,
    public Archivo: string,
    public FechaDeEntrega: string,
    public FechaDeCompra: string,


  ) {}
}
