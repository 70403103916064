export class EDF {
  constructor(
    public AnchoHojaArea: string,
    public AnchoHojaGenerica: string,
    public AreaMPliego: string,
    public CajaPorLamina: string,
    public CajaPorLaminaArea: string,
    public Cantidad: string,
    public CejaCierre: string,
    public ClaveHojaArea: string,
    public ClaveHojaGenerica: string,
    public ColorDePapel: string,
    public Corrugado: string,
    public FabricacionDeMuestra: string,
    public FactibleLaElaboracion: string,
    public Flauta: string,
    public HerramentalesEspeciales: string,
    public ImpresionGcmiNo1: string,
    public ImpresionGcmiNo2: string,
    public ImpresionGcmiNo3: string,
    public LargoHojaArea: string,
    public LargoHojaGenerica: string,
    public MedidasExterioresAlto: string,
    public MedidasExterioresAncho: string,
    public MedidasExterioresLargo: string,
    public MedidasInterioresAlto: string,
    public MedidasInterioresAncho: string,
    public MedidasInterioresLargo: string,
    public MinimoVenta: string,
    public Observaciones: string,
    public PiezasPorJuego: string,
    public PiezasPorJuegoArea: string,
    public ProductoAEmpacar: string,
    public Resistencia: string,
    public TipoDeEmpaque: string,
    public TipoDeResistencia: string,
    public bloquear: string,
    public comentario: string,
    public created_at: string,
    public id: string,
    public id_user: string,
    public id_workstatuparciales: string,
    public id_workstatusend: string,
    public updated_at: string
  ) {}
}
