import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  ModalController,
  IonSlides,
  AlertController,
  LoadingController,
} from '@ionic/angular';
import { SipocService } from '../services/sipoc.service';
import { UserService } from '../services/user.service';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { Sipoc } from '../models/sipoc';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

export class VerDatosModalPage {}
@Component({
  selector: 'app-finalizar-modal',
  templateUrl: './finalizar-modal.page.html',
  styleUrls: ['./finalizar-modal.page.scss'],
  providers: [UserService, SipocService],
})
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: FinalizarModalPage,
      },
    ]),
  ],
  declarations: [FinalizarModalPage],
})
export class FinalizarModalPage implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  @Input('id') id: any;
  @Input('pedido') pedido: any;
  @Input('nombrecliente') nombrecliente: any;

  public user;

  public sipoc: Sipoc;
  public Guardar: String;
  public btnguardar: boolean;
  public status: String;
  public paso1boll: Boolean;

  loading;

  sliderOpts = {
    zoom: true,
  };

  constructor(
    public loadingController: LoadingController,
    public alertController: AlertController,
    private _userService: UserService,
    private modalController: ModalController,
    private _sipoc: SipocService
  ) {}

  ngOnInit() {
    this.Guardar = 'Guardar';
    this.sipoc = new Sipoc(
      1,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      0,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      null,
      0,
      0
    );
    this.btnguardar = false;

    this.identy();
    this._sipoc.getverdatos(this.id, this.user.maquina).subscribe(
      (response) => {
        if (response.sipoc) {
          this.sipoc = response.sipoc;
          console.log(response.sipoc);
          this.paso1boll = false;

          if (response.almacen.maquina1 === this.user.maquina) {
            this.sipoc.cantidadR = response.almacen.recibida1;
            this.sipoc.cantidadP = response.almacen.paso1;
            this.paso1boll = true;

          }
          if (response.almacen.maquina2 === this.user.maquina) {
            this.sipoc.cantidadR = response.almacen.recibida2;
            this.sipoc.cantidadP = response.almacen.paso2;
          }
          if (response.almacen.maquina3 === this.user.maquina) {
            this.sipoc.cantidadR = response.almacen.recibida3;
            this.sipoc.cantidadP = response.almacen.paso3;
          }
          if (response.almacen.maquina4 === this.user.maquina) {
            this.sipoc.cantidadR = response.almacen.recibida4;
            this.sipoc.cantidadP = response.almacen.paso4;
          }
        }
      },
      (error) => {
        var errorMessage = <any>error;
        if (!errorMessage != null) {
          // this.status="error";
          // console.log(errorMessage);
        }
      }
    );
  }
  identy() {
    this.user = this._userService.getUsuario();
  }
  ionViewDidEnter() {
    // this.slides.update();
  }

  async zoom(zoomIn: boolean) {
    const slider = await this.slides.getSwiper();
    const zoom = slider.zoom;
    zoomIn ? zoom.in() : zoom.out();
  }

  close(tre) {
    this.modalController.dismiss(tre);
  }
  async presentAlertMultipleButtons(sipoc) {
    const alert = await this.alertController.create({
      cssClass: 'my-confirmacion-class',
      header: '¿Confirmar la operacion?',
      subHeader: '',
      message: `Cantidad Recibida ${this.sipoc.cantidadR}    
      Cantidad Producida ${this.sipoc.cantidadP}   OCP: ${this.sipoc.OCP}`,

      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
        {
          text: 'Guardar',
          handler: () => {
            this.btnguardar = true;

            this.buttonClickApagar(sipoc);
          },
        },
      ],
    });
    await alert.present();
  }

  buttonClickApagar(sipoc) {
    //alert(sipoc.id);
    sipoc.color = 'success';
    this.Guardar = 'Subiendo';
    this.btnguardar = true;

    this._sipoc
      .getsipocapagar(
        sipoc.id,
        this.user.maquina,
        sipoc.cantidadR,
        sipoc.cantidadP,
        sipoc.OCP,
        sipoc.MaterialDEV
      )
      .subscribe(
        (response) => {
          this.close(true);
        },
        (error) => {
          var errorMessage = <any>error;
          if (!errorMessage != null) {
            this.status = errorMessage;
            // console.log(errorMessage);
            this.btnguardar = false;
          }
        }
      );
  }
}
