import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { Imagen } from '../models/imagen';
import { SipocService } from '../services/sipoc.service';
import { UserService } from '../services/user.service';
import { BrowserModule } from '@angular/platform-browser'
import { CommonModule } from "@angular/common";
import { Sipoc } from '../models/sipoc';
import { RQC } from '../models/rqc';
import { EDF } from '../models/edf';

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
@Component({
  selector: 'app-verdatos-modal',
  templateUrl: './verdatos-modal.page.html',
  styleUrls: ['./verdatos-modal.page.scss'],
  providers: [UserService,SipocService]

})

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: VerDatosModalPage
      }
    ])
  ],
  declarations: [VerDatosModalPage]
})

export class VerDatosModalPage implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  @Input('id')id: any;
  @Input('pedido')pedido: any;
  @Input('nombrecliente')nombrecliente: any;
  public user;
  public sipoc: Sipoc;
  public edf: EDF;
  public rqc: RQC;

  sliderOpts = {
    zoom: true
  };
 
  constructor(private _userService: UserService,private modalController: ModalController,private _sipoc: SipocService) { 


  }
 

  ngOnInit() {
    
    this.sipoc = new Sipoc(1,"","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",0,"","","","","","","","","",null,0,0);
    this.edf = new EDF("","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","");
    this.rqc = new RQC("","","","","","","","","","","","","","","");

    this.loadc();
this.identy();
    
  }
   identy() {
    this.user = this._userService.getUsuario();
  }
  ionViewDidEnter(){
   // this.slides.update();

  }
 loadc(){
  this._sipoc.getverdatos(this.id,this.sipoc.componenete).subscribe(
    response=>{
      
      if(response.eft){
        this.edf = response.eft;

      }

      if(response.rqc){
        this.rqc = response.rqc;
      }

      if(response.sipoc){
        this.sipoc = response.sipoc;
        this.sipoc.componenete = response.componenete;

      }
      

    },
    error =>{
        var errorMessage=<any>error;
        if(!errorMessage != null){
           // this.status="error";
           // console.log(errorMessage);

        }

    }
); 
 }
  async zoom(zoomIn: boolean) {
    const slider = await this.slides.getSwiper();
    const zoom = slider.zoom;
    zoomIn ? zoom.in() : zoom.out();
  }
 
  close() {
    this.modalController.dismiss();
  }
 

  
}