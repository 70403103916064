import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ImageModalPage } from './image-modal/image-modal.page';

import { VerDatosModalPage } from './verdatos/verdatos-modal.page';
import { FinalizarModalPage } from './finalizar/finalizar-modal.page';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import { UserService } from './services/user.service';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  
} from '@capacitor/core';

const { PushNotifications,SplashScreen,Share  } = Plugins;
@NgModule({
  declarations: [AppComponent,ImageModalPage,VerDatosModalPage,FinalizarModalPage],
  entryComponents: [ImageModalPage,VerDatosModalPage,FinalizarModalPage],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,    HttpClientModule,
  ],
  providers: [
    UserService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
