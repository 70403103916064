export class Sipoc {
  constructor(
    public componenete: number,

    
    public ComentarioPlaneacion: string,

    public CantidadRQ: string,
    public CantidadEsperada: string,

    public HOJA: string,

    public CJASXLAMINA: string,
    public CLIENTE: string,
    public COBRADO: string,
    public COTIZACION: string,
    public DISENO: string,
    public DMA: string,
    public ENTALMMP: string,
    public ENTALMPT: string,
    public FACTURA: string,
    public FOV: string,
    public FPEDIDO: string,
    public MPAUTLIZAR: string,
    public OC: string,
    public OCOMPRA: string,
    public OP: string,
    public PEDIDO: string,
    public PEG: string,
    public PT: string,
    public REQ: string,
    public SOL: string,
    public SOLICITADA: string,
    public SOLICITUD: string,
    public comentario: string,
    public created_at: string,
    public estatus: string,
    public fecha: string,
    public id: number,
    public id_area: string,
    public updated_at: string,


    public C: string,

    public cantidadR: string,
    public cantidadP: string,
    public OCP: string,

    public CMPRQ: string,
    public CMPESPERADA: string,
    public PzasPROG: string,
    
    
    public clientetodo: JSON,
    public MaterialDEV: number,
    public CantSurtida: number,

    
  ) { }

}

