import { Component, OnInit, ViewChild, Input, Output } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { Imagen } from '../models/imagen';
import { SipocService } from '../services/sipoc.service';
import { UserService } from '../services/user.service';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { Sipoc } from '../models/sipoc';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.page.html',
  styleUrls: ['./image-modal.page.scss'],
  providers: [UserService, SipocService],
})
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: ImageModalPage
      }
    ])
  ],
  declarations: [ImageModalPage]
})


export class ImageModalPage implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  @Input('id') id: any;
  @Input('pedido') pedido: any;
  @Input('nombrecliente') nombrecliente: any;

  public arrayimagen: Imagen[] = [];
  public user;
  public sipoc: Sipoc;

  sliderOpts = {
    zoom: true,
  };

  constructor(
    private _userService: UserService,
    private modalController: ModalController,
    private _sipoc: SipocService
  ) {
    this.sipoc = new Sipoc(
      1,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      0,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      null,
      0,0
    );

    this.arrayimagen = [];
  }

  ngOnInit() {
    this.identy();
    this.loadc();
  }
  loadc() {
    this._sipoc.getimagenes(this.id, this.sipoc.componenete).subscribe(
      (response) => {
        this.arrayimagen = [];
        if (response.imagenes) {
          response.imagenes.forEach((element) => {
            //https://scmecobasa.com/storage/uploads/peg/2gU0kAPhv9VJfcMv.jpg
            var imagen = new Imagen(
              'https://scmecobasa.com' + element.ubicacion
            );
            this.arrayimagen.push(imagen);
          });
        }
        if (response.imagenesHojaCorte) {
          response.imagenesHojaCorte.forEach((element) => {
            //https://scmecobasa.com/storage/uploads/peg/2gU0kAPhv9VJfcMv.jpg
            var imagen = new Imagen(
              'https://scmecobasa.com' + element.ubicacion
            );
            this.arrayimagen.push(imagen);
          });
        }
        this.sipoc.componenete = response.componenete;
      },
      (error) => {
        var errorMessage = <any>error;
        if (!errorMessage != null) {
          // this.status="error";
          // console.log(errorMessage);
        }
      }
    );
  }
  identy() {
    this.user = this._userService.getUsuario();
  }
  ionViewDidEnter() {
    // this.slides.update();
  }

  async zoom(zoomIn: boolean) {
    const slider = await this.slides.getSwiper();
    const zoom = slider.zoom;
    zoomIn ? zoom.in() : zoom.out();
  }

  close() {
    this.modalController.dismiss();
  }
}
